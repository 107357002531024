@font-face {
    font-family: "Lyon Regular";
    src: url("https://res.cloudinary.com/juangomez/raw/upload/v1612006263/LyonDisplay-Regular_ansxbq.otf")format("opentype");
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: "Lyon Italic";
    src: url("https://res.cloudinary.com/juangomez/raw/upload/v1612006264/LyonDisplay-RegularItalic_etxg2c.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lyon Bold";
    src: url("https://res.cloudinary.com/juangomez/raw/upload/v1612028237/LyonDisplay-Bold_t98hpn.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}


